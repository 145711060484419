import { Link } from "@remix-run/react";
import { useIsAnonymousUser } from "../util/use-logged-in-user";

export function AppLogo() {
    const isAnonymousUser = useIsAnonymousUser()
    return (
        <Link to={isAnonymousUser ? '/' : '/h'} className="group grid leading-snug">
            <img src="https://boringnotes-app.b-cdn.net/logo_light_200.avif" width={100} alt="Boring Notes Logo" loading="lazy" />
        </Link>
    )
}
